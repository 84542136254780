
  import { defineComponent, onMounted, ref, Ref } from "vue";
  import useEmitter from "@/composables/Emmiter";
  import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
  import { ElConfigProvider } from "element-plus";
  import ptBr from "element-plus/lib/locale/lang/pt-br";
  import { date } from "yup";
  import Api from "@/services/Api";

  
  export default defineComponent({
    name: "FilterLogProdutos",
    components: {
      FilterBase,
      ElConfigProvider,
    },
  
    setup(props, { emit }) {
      const emitter = useEmitter();
  
      const activeModal = ref(false);
      const closeModal = ref(false);
  
      const filtro: Ref<any> = ref({ lojas: [], automocoes: [], comErro: null });
      const automocoes: Ref<any> = ref([]);
      const lojas: Ref<any> = ref([]);
  
      async function enviarEmit() {
  
        await emitter.emit("FilterLogProdutos", filtro.value);
  
        closeModal.value = !closeModal.value;
  
      }
  
  
      onMounted(async()=>{ 

        lojas.value = (await Api.get('getAllStores') as any).data.lojas || [];
        automocoes.value = (await Api.get('getAutomocoes') as any).data.automocoes || [];

      });
  
      return {
        filtro,
        lojas,
        automocoes,
        enviarEmit,
        closeModal,
        ElConfigProvider,
        ptBr,
      };
    },
  });
  